import './TimelineSection.scss';
import React, { useEffect, useState } from "react";
import { Timeline } from 'antd';

function TimelineSection() {
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;

    return (
        <>
            <div className='roadmap-container pad-l-r-24'>
                <p className="small-label">A history of Skin Care Innovation</p>
                <h3 className="heading">Roadmap</h3>
                <div className="description">
                    <p className="line">Skin health is an important part of us all. In 2020, Bowhead Health Inc. won 1st place from a leading life science organization. This prize was a Global Atopic Dermatitis Challenge from a multinational global company. You can read the full press release <a href="#" className='link'>here.</a> </p>
                    <p className="line">Fast forward to today and we now have an app live and have begun the process of Open-Sourcing our Atopic Dermatitis application. Bowhead has partnered with the Dhealth Foundation in Switzerland with the plans to empower individuals to own their data, fuel innovation, decentralize healthcare data and ultimately improve patient outcomes. </p>
                    <p className="line">For more information read our complete  <a href="#" className='link'>Frequently Asked Questions.</a> Plans and roadmap subject to change.</p>
                </div>

                <div className="timeline-container">
                    { !isMobile && <Timeline mode="alternate">
                        <Timeline.Item dot={''} className="first-item">
                        </Timeline.Item>
                        <Timeline.Item label="/ December 2020" position="right">
                            <div className="card right-card">
                                Bowhead Health Inc. developers win
                                Global Atopic Dermatitis challenge
                                organized by Top 10 Pharma Company
                            </div>
                        </Timeline.Item>
                        <Timeline.Item label="December 2021 /" position="left">
                            <div className="card left-card">
                                Atopic Dermatitis Pilot modules
                                launches in Germany with Top 10 Pharma Co.
                            </div>
                        </Timeline.Item>
                        <Timeline.Item label="/ September 2022" position="right">
                            <div className="card right-card">
                                Atopic Dermatitis App Launches on iOS & Android Stores
                            </div>
                        </Timeline.Item>
                        <Timeline.Item label="October 2022 /" position="left" className='custom-item-padding'>
                            <div className="card left-card">
                                SKIN DAO launches, Bowhead
                                and dHEALTH as founding partners
                            </div>
                        </Timeline.Item>
                        <Timeline.Item label="" position="left">
                            <div className="card left-card">
                                Skin Data collection begins for $SKIN
                            </div>
                        </Timeline.Item>
                        <Timeline.Item label="/ January 2023" position="right">
                            <div className="card right-card">
                                First $SKIN-Powered Machine Learning Model Released
                            </div>
                        </Timeline.Item>
                        <Timeline.Item label="March 2023 /" position="left">
                            <div className="card left-card">
                                Skin Data Marketplace Launches, Partnership with Skincare Co. Announced
                            </div>
                        </Timeline.Item>
                        <Timeline.Item label="/ April 2023" position="right">
                            <div className="card right-card">
                                First Skin DAO proposals submitted,
                                1st DAO Votes occurs 30 April 30th, 2023
                            </div>
                        </Timeline.Item>
                        <Timeline.Item label="" position="right" className='future-timeline-item'>
                            <div className="future">FUTURE OF SKIN</div>
                        </Timeline.Item>
                    </Timeline> }


                    { isMobile && <Timeline mode="left">
                        <Timeline.Item dot={''} className="first-item"></Timeline.Item>
                        <Timeline.Item position="right">
                            <h2 className='date'>/ December 2020</h2>
                            <div className="card right-card">
                                Bowhead Health Inc. developers win
                                Global Atopic Dermatitis challenge
                                organized by Top 10 Pharma Company
                            </div>
                        </Timeline.Item>
                        <Timeline.Item position="right">
                            <h2 className='date'>/ December 2020</h2>
                            <div className="card right-card">
                                Bowhead Health Inc. developers win
                                Global Atopic Dermatitis challenge
                                organized by Top 10 Pharma Company
                            </div>
                        </Timeline.Item>
                        <Timeline.Item position="right">
                            <h2 className='date'>/ December 2020</h2>
                            <div className="card right-card">
                                Bowhead Health Inc. developers win
                                Global Atopic Dermatitis challenge
                                organized by Top 10 Pharma Company
                            </div>
                        </Timeline.Item>
                        <Timeline.Item position="right">
                            <h2 className='date'>/ December 2020</h2>
                            <div className="card right-card">
                                Bowhead Health Inc. developers win
                                Global Atopic Dermatitis challenge
                                organized by Top 10 Pharma Company
                            </div>
                        </Timeline.Item>
                        <Timeline.Item label="" position="right" className='future-timeline-item'>
                            <div className="future">FUTURE OF SKIN</div>
                        </Timeline.Item>
                    </Timeline>
                }
                </div>
            </div>
        </>
    );
}

export default TimelineSection;