import './DataSection.scss';
import React from "react";
import { Col, Row } from 'antd';
import {
  SafeView,
  CheckedIcon
} from "@images";

function DataSection() {
  return (
    <div className="data-section pad-l-r-24">
    <div className="section-title">
      <p className='subtitle'>What sets Skin DAO apart from the other 380, 000+ digital health apps?</p>
      <h2 className='title'>Your Data is Safe with You</h2>
    </div>

    <Row gutter={20} className="flex-center">
        <Col span={12} md={12} xl={12} xxl={24} xs={24}>
          <img className="safe-img" src={SafeView} alt="" />
        </Col>
        <Col span={12} md={12} xl={12} xxl={24} xs={24}>
          <div className="list">
            <div className="item">
              <img src={CheckedIcon} alt="" />
              <h2>No email or phone number collected</h2>
            </div>
            <div className="item">
              <img src={CheckedIcon} alt="" />
              <h2>End-to-end encryption report sharing</h2>
            </div>
            <div className="item">
              <img src={CheckedIcon} alt="" />
              <h2>Open-Source components for community building</h2>
            </div>
          </div>
        </Col>
      </Row>
  </div>
  );
}

export default DataSection;