import "./style.scss";
import React from "react";
import BannerSection from "../components/banner-section/BannerSection";
import SliderSection from "../components/slider-section/SliderSection";
import InfoSection from "../components/info-section/InfoSection";
import EarnSection from "../components/earn-section/EarnSection";
import DataSection from "../components/data-section/DataSection";
import ProvenTeamSection from "../components/proven-team-section/ProvenTeamSection";
import TimelineSection from "../components/timeline-section/TimelineSection";

function LandingPage() {
  return (
    <div className="main-container">
      <BannerSection></BannerSection>
      <SliderSection></SliderSection>
      <InfoSection></InfoSection>
      <DataSection></DataSection>
      <EarnSection></EarnSection>
      {/* <div className="chart-section">
        <div className="img-container">
          <div className="section-title">
            <p className='subtitle'>1 Billion $SKIN Tokens Distributed Over 10 years</p>
            <h2 className='title'>Meaningful Tokenomics</h2>
          </div>
        </div>
      </div> */}
      <TimelineSection></TimelineSection>
      <ProvenTeamSection></ProvenTeamSection>
    </div>
  );
}

export default LandingPage;
