import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { LandingPage } from "@pages";
import { Button, Layout, Result } from "antd";
import { Content } from "antd/lib/layout/layout";

function App() {
  return (
    <BrowserRouter>
      <Routes>
{/*         
        <Route path="/" element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route path="/home" element={<LandingPage />} />
        </Route> */}

        <Route
          path="*"
          element={
            <Layout className="site-layout">
            <Content className="site-layout-background">
              <LandingPage/>
            </Content>
          </Layout>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
