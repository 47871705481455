import "./SliderSection.scss";
import React from "react";
import { Hipaa, Forbes, GDPR, EitHealth, INC, Stat } from "@images";

function SliderSection() {
  const companyList = [
    {
      img: Hipaa,
      key: 1,
    },
    {
      img: GDPR,
      key: 2,
      className: 'mini-icon'
    },
    {
      img: EitHealth,
      key: 3,
    },
    {
      img: Stat,
      key: 4,
    },
    {
      img: Forbes,
      key: 5,
    },
    {
      img: INC,
      key: 6,
    },
  ];

  return (
    <>
      <div className="slider pad-l-r-24">
        {companyList.map((x) => {
          return <img className={x.className} key={x.key} src={x.img} alt="no img" />;
        })}
      </div>
    </>
  );
}

export default SliderSection;
