import "./InfoSection.scss";
import React from "react";
import { Button, Col, Row } from "antd";
import {
  HandImgPng,
  tileLogo,
  HandWithImg,
  CubeIcon,
  SocialIcon,
  GradientBottom,
} from "@images";

function InfoSection() {
  return (
    <div className="info-section">
      <div className="pad-l-r-40">
        <Row gutter={20}>
          <Col span={12} md={12} xl={12} xxl={24} xs={24}>
            <div className="container">
              <p className="sm-txt">Rain or Shine</p>
              <h2 className="lg-txt">YOUR SKIN HAS YOU COVERED</h2>
              <img className="tile-logo" src={tileLogo} alt="no img" />
              <div className="description">
                <p className="sm-txt">
                  Your skin is your body’s largest organ - protecting you from
                  the outside world and pathogens. It also helps with
                  temperature regulation, It also helps with temperature
                  regulation, immune defence, vitamin production, and sensation.
                </p>
                <p className="sm-txt">
                  There is a huge focus on skin health, with fierce competition
                  to have glowing, clearer, healthier, younger and fresher skin.
                  And this focus can cause secondary problems with self-esteem
                  and mental health.
                </p>
                <p className="sm-txt">
                  Skin DAO focuses on innovations in Skin Health and Research.
                </p>
              </div>
            </div>
          </Col>
          <Col span={12} md={12} xl={12} xxl={24} xs={24}>
            <img className="hand-img" src={HandImgPng} alt="no img" />
          </Col>
        </Row>
      </div>

      <div className="img-container">
        <Row className="pad-l-r-40" gutter={20}>
          <Col span={12} md={12} xl={12} xxl={24} xs={24}>
            <img className="hand-with-img" src={HandWithImg} alt="no data" />
          </Col>
          <Col span={12} md={12} xl={12} xxl={24} xs={24}>
            <div className="container">
              <p className="sm-txt">Earn $SKIN for your Skin Data</p>
              <h2 className="lg-txt">Join the $SKIN MOVEMENT</h2>
              <img className="cube-icon" src={CubeIcon} alt="no data" />
              <div className="desc">
                <p className="sm-txt">
                  Our mission is to build the world’s first SKIN DAO where the
                  data, platform, machine vision algorithms and research
                  innovations are community-driven.
                </p>
                <p className="sm-txt">
                  The SKIN DAO app is already live and modules have been used by
                  global leading life science organizations.
                </p>
              </div>
              <div className="social-icons-container">
                <img className="social-icons" src={SocialIcon} alt="no data" />
              </div>
            </div>
          </Col>
        </Row>

        <img alt="" className="gradient-top" src={GradientBottom} />
        <img alt="" className="gradient-bottom" src={GradientBottom} />
      </div>

      <div className="buttons">
        {/* <Button className="custom-button" type="primary">
          Earn $SKIN
        </Button>
        <Button className="custom-button" type="primary">
          Buy $SKIN
        </Button> */}

        <button className="btn">
          Earn $SKIN
        </button>

        <button className="btn-2">
          Buy $SKIN
        </button>
      </div>

      <p className="desc-txt">
        Please note $SKIN is not a financial asset or holds any value. $SKIN
        represents a tokenized governance vote for the Skin DAO project. We make
        no warranties or representations regarding value, tradeability or
        ongoing viability of the project. Please be careful with your recovery
        seed within Skin DAO app, if you lose your keys we cannot recover your
        account. Buying $SKIN is not available for US or Canadian users.
      </p>
    </div>
  );
}

export default InfoSection;
