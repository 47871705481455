import "./EarnSection.scss";
import { Col, Row } from "antd";
import { SkinCoin, Barcode } from "@images";

function EarnSection() {
  return (
    <div className="earn-section pad-l-r-40">
      <div className="section-title">
        <p className="subtitle">
          All $SKIN tokens earned have a 1 year lock-up period
        </p>
        <h2 className="title">Earn $SKIN for your data</h2>
      </div>

      <Row gutter={20} className="flex-center">
        <Col span={16} md={24} xs={24} xl={16}>
          <div className="list">
            <div className="item">
              <Row>
                <Col className="align-center" span={3}>
                  <h2>+10</h2>
                </Col>
                <Col span={2}>
                  <img src={SkinCoin} alt="" />
                </Col>
                <Col className="align-center" span={19}>
                  <p>8 minute survey in app</p>
                </Col>
              </Row>
            </div>

            <div className="item">
              <Row>
                <Col className="align-center" span={3}>
                  <h2>+30</h2>
                </Col>
                <Col span={2}>
                  <img src={SkinCoin} alt="" />
                </Col>
                <Col className="align-center" span={19}>
                  <p>15 second anonymous elbow selfie</p>
                </Col>
              </Row>
            </div>

            <div className="item">
              <Row>
                <Col className="align-center" span={3}>
                  <h2>+50</h2>
                </Col>
                <Col span={2}>
                  <img src={SkinCoin} alt="" />
                </Col>
                <Col className="align-center" span={19}>
                  <p>Scan your current skin products</p>
                </Col>
              </Row>
            </div>

            <div className="item">
              <Row>
                <Col className="align-center" span={3}>
                  <h2>+100</h2>
                </Col>
                <Col span={2}>
                  <img src={SkinCoin} alt="" />
                </Col>
                <Col className="align-center" span={19}>
                  <p>Skin biopsy reports</p>
                </Col>
              </Row>
            </div>

            <div className="item">
              <Row>
                <Col className="align-center" span={3}>
                  <h2>+100</h2>
                </Col>
                <Col span={2}>
                  <img src={SkinCoin} alt="" />
                </Col>
                <Col className="align-center" span={19}>
                  <p>Your genome (23andme, etc.)</p>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col span={8} md={24} xs={24} xl={8}>
          <div className="barcode-box">
            <h2>
              Start by downloading <br />
              free SKIN DAO app
            </h2>
            <img className="barcode-img" src={Barcode} alt="" />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default EarnSection;
