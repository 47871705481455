import './ProvenTeamSection.scss';
import React from "react";
import { Col, Row } from 'antd';


function ProvenTeamSection() {
    return (
        <>
            <div className='proven-team-container pad-l-r-24'>
                <p className="small-label">A proven team of skin digital health experts</p>
                <h3 className="heading">A Proven Team</h3>
                <p className="desc">Skin DAO started off as a single atopic dermatitis patient with a dream has become a global movement.</p>
                <Row gutter={20} justify="center">
                    <Col span={6} md={12} xl={6} xxl={6} xs={24}>
                        <div className="card first-card">
                            <h3 className="card-title">1000+</h3>
                            <p className="card-description">Hours of
                                <br /> development</p>
                        </div>
                    </Col>
                    <Col span={6} md={12} xl={6} xxl={6} xs={24}>
                        <div className="card second-card">
                            <h3 className="card-title">100+</h3>
                            <p className="card-description">Years of Combined
                                <br /> Experience</p>
                        </div>
                    </Col>
                    <Col span={6} md={12} xl={6} xxl={6} xs={24}>
                        <div className="card third-card">
                            <h3 className="card-title">ONE</h3>
                            <p className="card-description">Vision for the
                                <br />  Future of $SKIN</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ProvenTeamSection;
