import "./BannerSection.scss";
import React from "react";
import { HandIcon } from "@images";
import { Input } from "antd";
import Video from "../../../assets/images/Render.mp4";

function BannerSection() {
  return (
    <>
      <div className="bg-video-wrap">
        <video height={'100%'} width={'100%'} src={Video} loop muted autoPlay></video>
        <div className="overlay"></div>
        <div className="overlay-content">
          <img src={HandIcon} alt="img" />
          <p className="description-txt">Welcome to Skin DAO!</p>
          <h2 className="center-txt">
            Get Early Access to $SKIN <br /> FUTURE OF SKINCARE DAO
          </h2>
          <p className="description-txt">Enter your email below to start:</p>
          <div className="input-container">
            <Input className="custom-input" />
            <button className="btn">
              Get Early Access
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BannerSection;
